<template>
  <div class="census"  v-bind="attrs">
    <div class="item" v-for="(item,index) in censusList" :key="index">
      <div class="title">{{ item.title }}</div>
      <div class="num">
        {{ item.value }}
        <span class="unit" v-if="item.unit">{{item.unit}}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs , useAttrs } from "vue";
const props = defineProps({
  censusList:{
    type: Array,
    default: ()=>[]
  }
})
const { censusList } = toRefs(props)
const attrs = useAttrs()
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" scoped>
.census{
  width: 100%;
  height: 88px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  background-color: #F1F3F7;
  .item{
    flex: 1;
    text-align: center;
    .title{
      color: $fsColor2;
      font-size: 14px;
    }
    .num{
      color: $fsColor;
      font-size: 24px;
      margin-top: 8px;
      .unit{
        font-size: 14px;
        color: $fsColor2;
      }
    }
  }
}

</style>
